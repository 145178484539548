<template>
  <div style="height: inherit">
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex" />
          </div>
        </div>
      </div>
    </section>

    <!-- {{ $store.state.spider.filteredData.map(p => p.rounds.map(t => t.played))}} -->

    <b-tabs
      v-if="c[page].length > 1"
      v-model="currentTab"
      content-class="pt-1"
      fill
    >
      <b-tab
        v-for="t in c[page]"
        :key="t.tabTitle"
        :title="$t(t.tabTitle)"
        lazy
      >
        <div v-if="!loading">
          <b-row>
            <b-col>
              <b-card-actions
                v-if="!currentConf.noGraph"
                action-collapse
                :title="$t(t.tabTitle)"
              >
                <radar
                  :data="series"
                  title=""
                  :labels="labels"
                  :percent="currentConf.percent"
                />
              </b-card-actions>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-card>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('Filter Players')"
                />
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-for="(table, index) in tables"
              :key="table.title"
              md="6"
            >
              <stats-table
                :title="table.title"
                :rows="table.data"
                :columns="columns"
                :page-length="10"
                :search-term="searchTerm"
                :tour="tour[index]['benchmarks']"
                :hide-tour="hideTour"
                :bench="benchmark"
                :comp-type="table.type"
                :info="table.info"
              />
            </b-col>
          </b-row>
        </div>

        <div v-else>
          <loading />
        </div>
      </b-tab>
    </b-tabs>

    <div
      v-else
    >
      <div v-if="!loading">
        <b-row>
          <b-col>
            <b-card-actions
              v-if="!currentConf.noGraph"
              action-collapse
              :title="$t(c[page][0].title)"
            >
              <radar
                :data="series"
                title=""
                :labels="labels"
                :percent="currentConf.percent"
              />
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Filter Players')"
              />
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            v-for="(table, index) in tables"
            :key="table.title"
            md="6"
          >
            <stats-table
              :title="table.title"
              :rows="table.data"
              :columns="columns"
              :page-length="10"
              :search-term="searchTerm"
              :tour="tour[index]['benchmarks']"
              :hide-tour="hideTour"
              :bench="benchmark"
              :comp-type="table.type"
              :info="table.info"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <loading />
      </div>
    </div>

    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :trends="trends"
        :benchmark="benchmark"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCard, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import Radar from '../../components/Radar.vue'
import StatsTable from '../../components/StatsTable.vue'
import Loading from '../../components/Loading.vue'
import configuration from './config'
import LeftFilterSidebar from '../../components/LeftFilterSidebar.vue'

export default {
  components: {
    // BOverlay,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    Radar,
    StatsTable,
    BCardActions,
    LeftFilterSidebar,
    Loading,
  },
  props: {
    page: {
      type: String,
      default: 'wastedShots',
    },
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tour: [],
      filters: {
        greenspeed: -1,
        rain: -1,
        wind: -1,
        temperature: null, // new
        holesNumber: 18, // played not 0
        qualification: -1,
        competition: -1,
        shotByShot: 1,
        roundType: null,
        selectedPlayers: [],
        selectedGroup: null,
        startDate: '',
        endDate: '',
      },
      trends: {
        greenspeed: -1,
        rain: -1,
        wind: -1,
        temperature: null, // new
        holesNumber: 18, // played not 0
        qualification: -1,
        competition: -1,
        shotByShot: 1,
        roundType: null,
        selectedPlayers: [],
        selectedGroup: null,
        startDate: '',
        endDate: '',
      },
      loadingTab: true,
      currentTab: 0,
      c: configuration,
      labels: [],
      tables: [],
      series: [],
      searchTerm: '',
      plop: 0,
      columns: [],
    }
  },
  computed: {
    config() {
      return [
        {
          title: this.$t('Total'),
          key: 'total',
        },
        {
          title: this.$t('Putting'),
          key: 'onGreen',
        },
        {
          title: this.$t('Off The Tee'),
          key: 'offTee',
        },
        {
          title: this.$t('Long Shots & Lay-Ups'),
          key: 'longShots',
        },
        {
          title: this.$t('Greenside'),
          key: 'around',
        },
        {
          title: this.$t('To The Green'),
          key: 'approach',
        },
      ]
    },
    filterOptions() {
      return {
        greenspeed: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Slow'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Fast'),
            value: 2,
          },
        ],
        rain: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('No Rain'),
            value: 0,
          },
          {
            text: this.$t('Rain'),
            value: 1,
          },
        ],
        wind: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Low'),
            value: 0,
          },
          {
            text: this.$t('Med'),
            value: 1,
          },
          {
            text: this.$t('Strong'),
            value: 2,
          },
        ],
        temperature: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Normal'),
            value: 0,
          },
          {
            text: this.$t('Hot'),
            value: 1,
          },
          {
            text: this.$t('Cold'),
            value: -1,
          },
        ],
        holesNumber: [
          {
            text: this.$t('9 Holes'),
            value: 9,
          },
          {
            text: this.$t('18 Holes'),
            value: 18,
          },
        ],
        qualification: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: this.$t('Normal'),
            value: 1,
          },
          {
            text: this.$t('Qualification'),
            value: 3,
          },
          {
            text: this.$t('Competition'),
            value: 2,
          },
        ],
        competition: [
          {
            text: this.$t('All'),
            value: -1,
          },
          {
            text: '1',
            value: 1,
          },
          {
            text: '2',
            value: 2,
          },
          {
            text: '3',
            value: 3,
          },
          {
            text: '4',
            value: 4,
          },
        ],
        shotByShot: [
          {
            text: this.$t('Flash'),
            value: 0,
          },
          {
            text: this.$t('Full'),
            value: 1,
          },
        ],
        roundType: [
          {
            text: this.$t('All'),
            value: null,
          },
          {
            text: this.$t('Worst 5'),
            value: 'worst_5',
          },
          {
            text: this.$t('Best 5'),
            value: 'best_5',
          },
          {
            text: this.$t('Last 1'),
            value: 'last_1',
          },
          {
            text: this.$t('Last 3'),
            value: 'last_3',
          },
          {
            text: this.$t('Last 5'),
            value: 'last_5',
          },
          {
            text: this.$t('Last 10'),
            value: 'last_10',
          },
          {
            text: this.$t('Last 15'),
            value: 'last_15',
          },
          {
            text: this.$t('Last 20'),
            value: 'last_20',
          },
        ],
      }
    },
    loading() {
      return this.$store.state.spider.loadingData
    },
    benchmark() {
      return this.$store.state.spider.benchmark
    },
    currentConf() {
      return this.c[this.page][this.currentTab]
    },
  },
  watch: {
    page(newVal) {
      this.getPageData(this.$store.state.spider.stats, configuration[newVal][0], this.$store.state.spider.trends)
      this.currentTab = 0
      if (newVal === 'proximity') {
        this.columns = [
          {
            label: '',
            field: 'rank',
            width: '100px',
          },
          {
            label: this.$t('Name'),
            field: 'full_name',
          },
          {
            label: this.$t('Target'),
            field: 'value',
            width: '100px',
          },
          {
            label: this.$t('Flag'),
            field: 'value2',
            width: '110px',
          },
        ]
      } else {
        this.columns = [
          {
            label: '',
            field: 'rank',
            width: '100px',
          },
          {
            label: this.$t('Name'),
            field: 'full_name',
          },
          {
            label: this.$t('Value'),
            field: 'value',
            width: '100px',
          },
          {
            label: this.$t('Comp.'),
            field: 'trend',
            width: '200px',
          },
        ]
      }
    },
    currentTab(newVal) {
      this.getPageData(this.$store.state.spider.stats, configuration[this.page][newVal], this.$store.state.spider.trends)
    },
    filters: {
      deep: true,
      handler(newVal) {
        this.$store.commit('spider/filterData', newVal)
        this.$store.commit('spider/updateStatistics')
        this.getPageData(this.$store.state.spider.stats, configuration[this.page][this.currentTab], this.$store.state.spider.trends)
      },
    },
    trends: {
      deep: true,
      handler(newVal) {
        this.$store.commit('spider/filterTrends', newVal)
        this.$store.commit('spider/updateStatistics')
        this.getPageData(this.$store.state.spider.stats, configuration[this.page][0], this.$store.state.spider.trends)
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch('spider/updatePlayers')
    this.$store.dispatch('spider/updateGroups')
  },
  created() {
    this.columns = [
      {
        label: '',
        field: 'rank',
        width: '100px',
      },
      {
        label: this.$t('Name'),
        field: 'full_name',
      },
      {
        label: this.$t('Value'),
        field: 'value',
        width: '100px',
      },
      {
        label: this.$t('Comp.'),
        field: 'trend',
        width: '200px',
      },
    ]
    if (this.groupId || this.$store.state.spider.selectedGroup) {
      this.filters.selectedGroup = this.$store.state.spider.groups.find(item => item.id === (this.groupId || this.$store.state.spider.selectedGroup))
    }
    this.$store.dispatch('spider/updateStats', { filters: this.filters, trends: this.trends }).then(() => {
      this.$store.commit('spider/filterTrends', this.trends)
      this.$store.commit('spider/filterData', this.filters)
      this.$store.commit('spider/updateStatistics')
      this.getPageData(this.$store.state.spider.stats, configuration[this.page][0], this.$store.state.spider.trends)
    })
  },
  methods: {
    formatValue(val) {
      if (Number.isNaN(val)) {
        return '-'
      }
      if (val === 'NaN') {
        return '-'
      }
      if (this.page === 'strokesGained' && val === '0.000') {
        return '-'
      }
      return val
    },
    getPageData(stats, config, trends) {
      this.loadingTab = true
      const scoringStats = []
      stats.forEach(stat => {
        scoringStats.push({ ...stat.player, ...stat.stats[config.key] })
      })
      const scoringTrends = []
      trends.forEach(stat => {
        scoringTrends.push({ ...stat.player, ...stat.stats[config.key] })
      })
      const tables = []
      const currentPlayers = this.filters.selectedGroup ? this.$store.state.spider.players.filter(item => this.filters.selectedGroup.players.includes(item.id)) : this.$store.state.spider.players

      config.tables.forEach(t => {
        const dtable = scoringStats.map(item => {
          let r = {}
          try {
            let value = '-'
            let trend = '-'
            let value2 = '-'
            let trend2 = '-'

            if (t.key === 'misReadPuttPercentage' && (this.filters.shotByShot === 0 || this.trends.shotByShot === 0)) {
              value = '-'
              trend = '-'
              value2 = '-'
              trend2 = '-'
            } else {
              value = this.formatValue(item[t.key])
              trend = this.formatValue(scoringTrends.filter(o => o.id === item.id)[0][t.key])
              value2 = this.formatValue(item[t.key2])
              trend2 = this.formatValue(item[t.key2])
            }

            r = {
              id: item.id,
              full_name: item.fullName,
              value,
              trend,
              value2,
              trend2,
            }
          } catch (error) {
            r = {
              id: item.id,
              full_name: item.fullName,
              value: this.formatValue(item[t.key]),
              trend: '-',
              value2: this.formatValue(item[t.key2]),
              trend2: '-',
            }
          }
          return r
        }).sort((a, b) => {
          if (t.benchmarktype === 'L') {
            return a.value - b.value
          }
          return b.value - a.value
        }).map((item, rank) => ({ ...item, rank: rank + 1 }))

        const missingPlayers = currentPlayers.filter(item => !dtable.map(i => i.id).includes(item.id))
        missingPlayers.forEach(mp => {
          dtable.push({
            id: mp.id,
            full_name: mp.full_name,
            value: '-',
            trend: '-',
            value2: '-',
            trend2: '-',
            rank: '-',
          })
        })

        if (t.units === 'both') {
          tables.push({
            title: t.title,
            info: t.info,
            type: t.benchmarktype,
            data: dtable,
          })
        } else if (this.$store.state.spider.units === t.units) {
          tables.push({
            title: t.title,
            info: t.info,
            type: t.benchmarktype,
            data: dtable,
          })
        }
      })
      const chartPlayersId = tables[0].data.map(item => item.id).slice(0, 5)
      const chartData = stats.filter(item => chartPlayersId.includes(item.player.id))
        .map(p => {
          const data = []
          config.tables.forEach(t => {
            if (t.units === 'both' || this.$store.state.spider.units === t.units) {
              data.push(p.stats[config.key][t.key])
            }
          })
          return {
            name: p.player.fullName,
            data,
          }
        })
      this.series = chartData
      this.hideTour = Boolean(config.hideTour)
      this.tables = tables
      this.labels = config.tables.filter(t => (t.units === 'both' || this.$store.state.spider.units === t.units)).map(item => this.$t(item.title))
      this.tour = config.tables.filter(t => (t.units === 'both' || this.$store.state.spider.units === t.units)).map(item => {
        const r = {
          key: item.key,
          benchmarks: item.benchmarks,
        }
        return r
      })
      this.loadingTab = false
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
    }
  },
}

</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
