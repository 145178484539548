<template>
  <b-card>
    <b-row>
      <b-col
        cols="6"
      >
        <h4 class="card-title">
          {{ $t(title) }}
        </h4>
      </b-col>
      <b-col
        v-if="info"
        cols="6"
        class="text-right"
      >
        <feather-icon
          :id="`popover-1-${title}`"
          icon="InfoIcon"
          class="mr-1"
          title="Test"
        />
        <b-popover
          :target="`popover-1-${title}`"
          placement="left"
          triggers="hover focus"
          :content="$t(info)"
        />
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="filteredColumns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :theme="themeColor"
      :line-numbers="lineNumbers"
      :sort-options="{
        enabled: sortable,
      }"
      :row-style-class="rowStyleClassFn"
      @on-row-click="onRowClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'full_name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.full_name }}</span>
        </span>

        <span v-else-if="props.column.field === 'rank'">
          <span v-if="props.formattedRow[props.column.field] === 1">
            <b-img
              :src="require('@/assets/images/icons/gold.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else-if="props.formattedRow[props.column.field] === 2">
            <b-img
              :src="require('@/assets/images/icons/silver.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else-if="props.formattedRow[props.column.field] === 3">
            <b-img
              :src="require('@/assets/images/icons/bronze.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <div
          v-else-if="props.column.field === 'trend'"
        >
          <table style="margin: 0; padding: 0; border: 0; width: 100%;">
            <tr style="margin: 0; padding: 0; border: 0;">
              <td style="margin: 0; padding: 0; border: 0; width: 50%;">
                {{ props.formattedRow[props.column.field] }}
              </td>
              <td style="margin: 0; padding: 0; border: 0; text-align:right; width: 50%;">
                <span
                  v-if="props.formattedRow['trend'] === '-'"
                />
                <b-img
                  v-else-if="props.formattedRow['trend'] > props.formattedRow['value']"
                  :src="trendUp"
                  alt="up"
                  height="35"
                />
                <b-img
                  v-else-if="props.formattedRow['trend'] < props.formattedRow['value']"
                  :src="trendDown"
                  alt="down"
                  height="35"
                />
                <b-img
                  v-else-if="props.formattedRow['trend'] === props.formattedRow['value']"
                  :src="trendNone"
                  alt="none"
                  height="35"
                />
              </td>
            </tr>
          </table>
        </div>

        <!-- Column: Common -->
        <span
          v-else-if="props.column.field === 'value'"
          class="text-nowrap"
        >
          <span
            v-if="(!bench && bench !== 0) || !tour || props.row.id === $store.state.spider.selectedPlayer"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span
            v-else-if="compType === 'H'"
            :style="`color:#${props.formattedRow[props.column.field]>=tour[bench] ? '266af5': 'FF08CC'};`"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <span
            v-else
            :style="`color:#${props.formattedRow[props.column.field]<tour[bench] ? '266af5': 'FF08CC'};`"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <!-- Column: Common -->
        <span
          v-else
          class="text-nowrap"
        >
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <b-pagination
              v-if="props.total > pageLength"
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <div
            v-if="tour && !hideTour"
          >
            <b-badge
              class="mt-1 mb-0 padding-tour"
              variant="dark"
            >
              {{ $t('Tours') }}: {{ tour[11] }}
            </b-badge>
          </div>
        </div>
      </template>

      <div slot="emptystate">
        {{ $t('No data available') }}
      </div>

    </vue-good-table>

  </b-card>
</template>

<script>
import {
  BPagination, BCard, BImg, VBPopover, BPopover, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import logoDark from '@/assets/images/logo/logo_dark.svg'
import logoLight from '@/assets/images/logo/logo_light.svg'
import trendUp from '@/assets/images/icons/positive.svg'
import trendDown from '@/assets/images/icons/negative.svg'
import trendNone from '@/assets/images/icons/neutre.svg'

export default {
  components: {
    BImg,
    BCard,
    VueGoodTable,
    BPagination,
    BBadge,
    BPopover,
    BRow,
    BCol,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  emits: ['deletePlayerFromGroup'],
  props: {
    hideTour: {
      type: Boolean,
      default: false,
    },
    tour: {
      type: Array,
      required: false,
      default: null,
    },
    bench: {
      type: Number,
      required: false,
      default: null,
    },
    info: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: 'Table Title',
    },
    pageLength: {
      type: Number,
      default: 5,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    lineNumbers: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    compType: {
      type: String,
      default: 'H',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    filteredColumns() {
      if (this.$store.state.spider.comparaison) {
        return this.columns
      }
      return this.columns.filter(item => item.field !== 'trend')
    },
    themeColor() {
      if (store.state.appConfig.layout.skin === 'light') {
        return ''
      }
      return 'nocturnal'
    },
    appLogo() {
      if (store.state.appConfig.layout.skin === 'light') {
        return logoDark
      }
      return logoLight
    },
    trendUp() {
      return trendUp
    },
    trendDown() {
      return trendDown
    },
    trendNone() {
      return trendNone
    },
  },
  methods: {
    onRowClick(params) {
      store.commit('spider/selectPlayer', params.row.id)
    },
    rowStyleClassFn(row) {
      if (row.id !== this.$store.state.spider.selectedPlayer) {
        return ''
      }
      if (!this.tour || (!this.bench && this.bench !== 0)) {
        return 'highlight'
      }
      if (this.compType === 'H' && row.value >= this.tour[this.bench]) {
        return 'highlight'
      }
      if (this.compType === 'H' && row.value < this.tour[this.bench]) {
        return 'highlight-low'
      }
      if (row.value >= this.tour[this.bench]) {
        return 'highlight-low'
      }
      return 'highlight'
    },
  },
}
</script>

<style lang="scss">
.highlight td, .highlight:hover td{
  background: #266af5 !important;
  color: #ffffff !important;
}
.highlight-low td, .highlight-low:hover td{
  background: #EA005D !important;
  color: #ffffff !important;
}

td{
  vertical-align: middle !important;
}
.padding-tour{
  padding: 0.5rem 0.85rem;
}
</style>
