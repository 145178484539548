<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{
          'show': mqShallShowLeftSidebar,
          'bg-light': mqShallShowLeftSidebar && $store.state.appConfig.layout.skin === 'light',
          'bg-dark': mqShallShowLeftSidebar && $store.state.appConfig.layout.skin === 'dark',
        }"
      >
        <b-card-actions
          action-collapse
          :title="$t('Benchmark')"
          :collapsed="false"
          class="text-right"
        >
          <feather-icon
            id="popover-1-bench"
            icon="InfoIcon"
            class="mb-1"
            title="Test"
          />
          <b-popover
            target="popover-1-bench"
            placement="left"
            triggers="hover focus"
          >
            {{ $t('Color code in correlation with selected Benchmark') }}<br>
            <span style="color:#266af5;">{{ $t('Blue = equal or better') }}</span><br>
            <span style="color:#FF08CC;">{{ $t('Red = worse') }}</span>
          </b-popover>

          <v-select
            id="benchmark"
            v-model="$store.state.spider.benchmark"
            :placeholder="$t('No benchmark')"
            :reduce="o => $store.state.spider.benchmarks.indexOf(o)"
            :options="$store.state.spider.benchmarks"
            :clearable="false"
          />
        </b-card-actions>

        <b-card-actions
          action-collapse
          :title="$t('Community')"
          :collapsed="false"
        >

          <label
            for="groups"
          >{{ $t('Groups') }}</label>
          <v-select
            id="groups"
            v-model="filters.selectedGroup"
            label="name"
            :placeholder="$t('All groups')"
            :options="$store.state.spider.groups"
            @option:selected="clearPlayers"
            @input="clearGroup"
          />

          <label
            for="players"
          >{{ $t('Players') }}</label>
          <v-select
            id="players"
            v-model="filters.selectedPlayers"
            multiple
            label="full_name"
            :reduce="p => p.id"
            :placeholder="$t('All players')"
            :options="filters.selectedGroup ? $store.state.spider.players.filter(item => filters.selectedGroup.players.includes(item.id)) : $store.state.spider.players"
          />

        </b-card-actions>

        <b-card-actions
          action-collapse
          :title="$t('Filters')"
          :collapsed="false"
        >
          <h4 class="left-filter-title">
            {{ $t('Options') }}
          </h4>

          <label
            for="speed"
          >{{ $t('Green Speed') }}</label>
          <v-select
            id="speed"
            v-model="filters.greenspeed"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.greenspeed"
            :clearable="false"
          />
          <label
            for="rain"
          >{{ $t('Rain') }}</label>
          <v-select
            id="rain"
            v-model="filters.rain"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.rain"
            :clearable="false"
          />
          <label
            for="wind"
          >{{ $t('Wind') }}</label>
          <v-select
            id="wind"
            v-model="filters.wind"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.wind"
            :clearable="false"
          />
          <label
            for="temperature"
          >{{ $t('Temperature') }}</label>
          <v-select
            id="temperature"
            v-model="filters.temperature"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.temperature"
            :clearable="false"
          />

          <h4 class="left-filter-title">
            {{ $t('Rounds') }}
          </h4>

          <b-button-group class="mb-1">
            <b-button
              v-for="btn in filterOptions.holesNumber"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === filters.holesNumber"
              @click="filters.holesNumber = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group><br />

          <!-- <div class="d-flex mb-1">
            <b-form-checkbox
              id="holesSwitch"
              v-model="filters.holesNumber"
              name="price-switch"
              :value="filterOptions.holesNumber[1].value"
              :unchecked-value="filterOptions.holesNumber[0].value"
              switch
            />
            <h6
              v-if="filters.holesNumber === filterOptions.holesNumber[1].value"
              class="mb-0"
            >
              {{ filterOptions.holesNumber[1].text }}
            </h6>
            <h6
              v-else
              class="mb-0"
            >
              {{ filterOptions.holesNumber[0].text }}
            </h6>
          </div> -->

          <b-button-group class="mb-1">
            <b-button
              v-for="btn in filterOptions.shotByShot"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === filters.shotByShot"
              @click="filters.shotByShot = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group><br />
          <!-- <div class="d-fl    -->

          <label
            for="qualification"
          >{{ $t('Type') }}</label>
          <v-select
            id="qualification"
            v-model="filters.qualification"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.qualification"
            :clearable="false"
          />
          <label
            v-if="filters.qualification === 2"
            for="competition"
          >{{ $t('Tournament round') }}</label>
          <v-select
            v-if="filters.qualification === 2"
            id="competition"
            v-model="filters.competition"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.competition"
            :clearable="false"
          />

          <label
            for="roundType"
          >{{ $t('Select Rounds') }}</label>
          <v-select
            id="roundType"
            v-model="filters.roundType"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.roundType"
            :clearable="false"
          />

          <h4 class="left-filter-title">
            {{ $t('Date range') }}
          </h4>
          <label
            for="filters-datepicker-start"
          >{{ $t('From') }}</label>
          <b-form-datepicker
            id="filters-datepicker-start"
            v-model="filters.startDate"
            v-bind="labels[$i18n.locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            :locale="$i18n.locale"
          />
          <label
            for="filters-datepicker-end"
          >{{ $t('To') }}</label>
          <b-form-datepicker
            id="filters-datepicker-end"
            v-model="filters.endDate"
            v-bind="labels[$i18n.locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            :locale="$i18n.locale"
          />

          <h4 class="left-filter-title">
            {{ $t('Comparison') }}
          </h4>
          <div class="d-flex mb-1 mt-1">
            <b-form-checkbox
              id="switchComp"
              v-model="$store.state.spider.comparaison"
              name="switchComp"
              switch
            />
            <h6
              class="mb-0"
            >
              {{ $t('Comparison') }}
            </h6>
          </div>

        </b-card-actions>

        <b-card-actions
          v-if="$store.state.spider.comparaison"
          action-collapse
          :title="$t('Comparison')"
          :collapsed="false"
        >
          <h4 class="left-filter-title">
            {{ $t('Options') }}
          </h4>

          <label
            for="speed"
          >{{ $t('Green Speed') }}</label>
          <v-select
            id="speed"
            v-model="trends.greenspeed"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.greenspeed"
            :clearable="false"
          />
          <label
            for="rain"
          >{{ $t('Rain') }}</label>
          <v-select
            id="rain"
            v-model="trends.rain"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.rain"
            :clearable="false"
          />
          <label
            for="wind"
          >{{ $t('Wind') }}</label>
          <v-select
            id="wind"
            v-model="trends.wind"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.wind"
            :clearable="false"
          />
          <label
            for="temperature"
          >{{ $t('Temperature') }}</label>
          <v-select
            id="temperature"
            v-model="trends.temperature"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.temperature"
            :clearable="false"
          />

          <h4 class="left-filter-title">
            {{ $t('Rounds') }}
          </h4>

          <b-button-group class="mb-1">
            <b-button
              v-for="btn in filterOptions.holesNumber"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === trends.holesNumber"
              @click="trends.holesNumber = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group><br />

          <!-- <div class="d-flex mb-1">
            <b-form-checkbox
              id="holesSwitchB"
              v-model="trends.holesNumber"
              name="price-switch"
              :value="filterOptions.holesNumber[1].value"
              :unchecked-value="filterOptions.holesNumber[0].value"
              switch
            />
            <h6
              v-if="trends.holesNumber === filterOptions.holesNumber[1].value"
              class="mb-0"
            >
              {{ filterOptions.holesNumber[1].text }}
            </h6>
            <h6
              v-else
              class="mb-0"
            >
              {{ filterOptions.holesNumber[0].text }}
            </h6>
          </div> -->

          <b-button-group class="mb-1">
            <b-button
              v-for="btn in filterOptions.shotByShot"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === trends.shotByShot"
              @click="trends.shotByShot = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group><br />
          <!-- <div class="d-flex mb-1">
            <b-form-checkbox
              id="fullSwitchB"
              v-model="trends.shotByShot"
              name="price-switch"
              :value="filterOptions.shotByShot[1].value"
              :unchecked-value="filterOptions.shotByShot[0].value"
              switch
            />
            <h6
              v-if="trends.shotByShot === filterOptions.shotByShot[1].value"
              class="mb-0"
            >
              {{ filterOptions.shotByShot[1].text }}
            </h6>
            <h6
              v-else
              class="mb-0"
            >
              {{ filterOptions.shotByShot[0].text }}
            </h6>
          </div> -->

          <label
            for="qualification"
          >{{ $t('Type') }}</label>
          <v-select
            id="qualification"
            v-model="trends.qualification"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.qualification"
            :clearable="false"
          />
          <label
            v-if="trends.qualification === 2"
            for="competition"
          >{{ $t('Tournament round') }}</label>
          <v-select
            v-if="trends.qualification === 2"
            id="competition"
            v-model="trends.competition"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.competition"
            :clearable="false"
          />

          <label
            for="roundType"
          >{{ $t('Select Rounds') }}</label>
          <v-select
            id="roundType"
            v-model="trends.roundType"
            label="text"
            :reduce="o => o.value"
            :options="filterOptions.roundType"
            :clearable="false"
          />

          <h4 class="left-filter-title">
            {{ $t('Date range') }}
          </h4>
          <label
            for="comparison-datepicker-start"
          >{{ $t('From') }}</label>
          <b-form-datepicker
            id="comparison-datepicker-start"
            v-model="trends.startDate"
            v-bind="labels[$i18n.locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            :locale="$i18n.locale"
          />
          <label
            for="comparison-datepicker-end"
          >{{ $t('To') }}</label>
          <b-form-datepicker
            id="comparison-datepicker-end"
            v-model="trends.endDate"
            v-bind="labels[$i18n.locale] || {}"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            :locale="$i18n.locale"
          />

        </b-card-actions>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BFormCheckbox, BFormDatepicker, BPopover, BButton, BButtonGroup,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormCheckbox,
    BCardActions,
    BFormDatepicker,
    vSelect,
    BPopover,
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    trends: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      labels: {
        fr: {
          weekdayHeaderFormat: 'narrow',
          labelPrevDecade: 'Décennie précédente',
          labelPrevYear: 'Année précédente',
          labelPrevMonth: 'Mois précédent',
          labelCurrentMonth: 'Mois en cours',
          labelNextMonth: 'Prochain mois',
          labelNextYear: 'Année prochaine',
          labelNextDecade: 'Prochaine décennie',
          labelToday: 'Aujourd\'hui',
          labelSelected: 'Selectionnée',
          labelNoDateSelected: 'Pas de date choisie',
          labelCalendar: 'Calendrier',
          labelNav: 'Navigation',
          labelHelp: 'Utilisez les flèches du clavier pour nagviguer',
        },
      },
    }
  },
  methods: {
    clearPlayers() {
      this.filters.selectedPlayers = []
    },
    clearGroup() {
      this.$store.commit('spider/setSelectedGroup', null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.hide-header{
  display: none;
}
.left-filter-title{
  font-size: 17px;
  margin: 1rem 0;
  font-weight: bold;
}
</style>
