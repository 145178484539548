<template>
  <b-card
    :title="$t(title)"
  >
    <vue-apex-charts
      v-if="data.length > 0"
      type="radar"
      :options="statisticsRadar.chartOptions"
      :series="data"
    />
    <span
      v-else
    >
      {{ $t('No data available with the current selection') }}
    </span>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'
import { $themeBreakpoints } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Chart Title',
    },
    labels: {
      type: Array,
      default: () => [1, 2, 3, 4, 5, 6, 7],
    },
    percent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    statisticsRadar() {
      return {
        chartOptions: {
          responsive: [
            {
              breakpoint: $themeBreakpoints.md,
              options: {
                xaxis: {
                  labels: {
                    show: false,
                    // style: {
                    //   fontColor: '#000',
                    //   fontSize: '14px',
                    //   // fontFamily: 'Arial',
                    // },
                  },
                },
                chart: {
                  height: 250,
                },
              },
            },
          ],
          chart: {
            // height: 700,
            type: 'radar',
            // dropShadow: {
            //   enabled: true,
            //   blur: 8,
            //   left: 1,
            //   top: 1,
            //   opacity: 0.2,
            // },
            toolbar: {
              show: false,
            },
            // offsetY: 5,
            // foreColor: '#000',
          },
          stroke: {
            width: 0,
          },
          dataLabels: {
            background: {
              foreColor: ['#ebe9f1'],
            },
          },
          legend: {
            show: true,
            labels: {
              colors: ['#a8a8a8'],
              useSeriesColors: false,
            },
          },
          // colors: ['#061826', '#153978', '#266af5', '#5cbdf9', '#a9a9a9', '#d7315f'],
          colors: ['#AAAAAA', '#FF5400', '#FF08CC', '#00FF9A', '#77ACFA'],
          plotOptions: {
            radar: {
              polygons: {
                strokeColors: ['#ebe9f1'],
                connectorColors: '#ebe9f1',
                fill: {
                  colors: [this.$store.state.appConfig.layout.skin === 'light' ? '#fff' : '#283046'],
                },
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          fill: {
            type: 'solid',
            // colors: ['#061826', '#153978', '#266af5', '#5cbdf9', '#a9a9a9', '#d7315f'],
            colors: ['#AAAAAA', '#FF5400', '#FF08CC', '#00FF9A', '#77ACFA'],
            opacity: 0.6,
            // type: 'gradient',
            // gradient: {
            //   shade: 'dark',
            //   gradientToColors: [$themeColors.primary, $themeColors.info],
            //   shadeIntensity: 1,
            //   type: 'horizontal',
            //   opacityFrom: 1,
            //   opacityTo: 1,
            //   stops: [0, 100, 100, 100],
            // },
          },
          labels: this.labels,
          // markers: {
          //   size: 0,
          // },
          yaxis: {
            show: true,
            tickAmount: 5,
            max: this.maxVal,
            labels: {
              style: {
                colors: ['#a8a8a8', '#a8a8a8', '#a8a8a8', '#a8a8a8', '#a8a8a8'],
                // cssClass: 'apexcharts-yaxis-label',
              },
              formatter(val) {
                return val.toFixed(1)
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                colors: '#000',
                fontSize: '14px',
                cssClass: 'apexcharts-yaxis-label',
              },
            },
          },
          grid: {
            show: false,
            padding: {
              // bottom: -27,
            },
          },
        },
      }
    },
  },
  methods: {
    maxVal(val) {
      if (this.percent) {
        return 100
      }
      return val * 1.1
    },
  },
}
</script>

<style>

</style>
