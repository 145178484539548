<template>
  <div>
    <b-card
      style="min-height:500px;"
      class="text-center"
    >
      <div class="pt-1">
        <b-progress
          :value="bar.value"
          :variant="bar.variant"
        />
      </div>
      <p
        class="pt-1"
      >
        {{ text }}
      </p>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BProgress,
  },
  data() {
    return {
      bar: { variant: 'success', value: 0 },
      timer: null,
      text: '',
    }
  },
  computed: {
    texts() {
      return [
        this.$t('Loading all your data ...'),
        this.$t('Computing scores for each rounds ...'),
        this.$t('Computing scores for each shots ...'),
      ]
    },
  },
  mounted() {
    /* eslint-disable */
    this.text = this.texts[0]
    this.timer = setInterval(() => {
      this.bar.value += 100 / 45
      if (this.bar.value > 10) {
        this.text = this.texts[1]
      }
      if (this.bar.value > 30) {
        this.text = this.texts[2]
      }
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>
